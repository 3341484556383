import axios from "axios"
import { useState, useEffect} from "react"
import { Row, Col } from "react-bootstrap"

const EditProductData = ({editItem}) => {
    const [name, setName] = useState('')
    const [price, setNewPrice] = useState('')
    const [status, setStatus] = useState('')


    return(
        <Row>
            <Col sm='12' md='3' lg='3' xl='3'></Col>
            <Col sm='12' md='6' lg='6' xl='6'>
            {status?.type === 'success' && <p style={{ margin: '20px' }} class="alert alert-success" role="alert">Success</p>}
            {status?.type === 'error' && <p style={{ margin: '20px' }} class="alert alert-danger" role="alert">Error!</p>}
            <form>
                <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">
                        New Product Name
                    </label>
                    <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    onChange={(e)=>setName(e.target.value)}
                    required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">
                        New Product Price
                    </label>
                    <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    onChange={(e)=>setNewPrice(e.target.value)}
                    required
                    />
                </div>
                <button style={{ width: "100%", border: "none", color: "white", height: "45px", backgroundColor: "#3452A3", marginTop:'5px'}} onClick={editItem}>
                    Edit Product
                </button>
            </form>
            </Col>
        </Row>
    )
}

export default EditProductData