import { Row, Col } from 'react-bootstrap'
import Navbar from '../../../side navbar/sidenav'
import axios from 'axios'
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleChevronLeft, faCircleChevronRight } from "@fortawesome/free-solid-svg-icons"

const EquatorialGeneralStoreRestockingRecords = () => {
    const [areInventoryRecordsLoading, setAreInventoryRecordsLoading] = useState(true)
    const [inventoryRecords, setInventoryRecords] = useState([])
    const [options, setOptions] = useState([])
    const [searchedItem, setSearchedItem] = useState(null)
    const [filteredRecords, setFilteredRecords] = useState([])

    const [recordsCurrentPage, setRecordsCurrentPage] = useState(1)

    const recordItemsPerPage = 10

    const incomingStartIndex = (recordsCurrentPage - 1) * recordItemsPerPage;
    const incomingEndIndex = incomingStartIndex + recordItemsPerPage;

    const totalPages = Math.ceil(inventoryRecords.length / recordItemsPerPage)

    const fetchShopInventoryRecords = async () => {
        let res = await axios.post('http://82.180.136.230:3005/fetchallequatorialgeneralstoreinventroyrestockrecords',{
            token: localStorage.getItem('token'),
            branch: localStorage.getItem('branch')
        })
        if(Array.isArray(res.data)){
            const sortedRecords = res.data.slice().sort((a, b) => {
                
                const [monthA, dayA, yearA] = a.date.split('/');
                const [monthB, dayB, yearB] = b.date.split('/');
                
                const normalizedDateA = new Date(`${monthA}/${dayA}/${yearA}`);
                const normalizedDateB = new Date(`${monthB}/${dayB}/${yearB}`);
                
                return normalizedDateB.getTime() - normalizedDateA.getTime();
            });
            setInventoryRecords(sortedRecords);
            setAreInventoryRecordsLoading(false);
        }
    }

    const fetchAllItems = async () => {
        let res = await axios.post('http://82.180.136.230:3005/fetchallshopproducts',{
            token: localStorage.getItem('token')
        })

        if(Array.isArray(res.data)){

            const transformedOptions = res.data.map((item) => ({
                value: item.productName,
                label: item.productName,
            }))
            setOptions(transformedOptions)
        }
    }
    
    const handleOnSelect = event => {
        setSearchedItem(event.value)
    }

    useEffect(()=> {
        fetchShopInventoryRecords()
        fetchAllItems()
    },[])

    useEffect(()=>{
        console.log('ss', inventoryRecords)
        if(searchedItem && inventoryRecords){
            const filtered = inventoryRecords.filter(record => record.productName === searchedItem)

            setFilteredRecords(filtered)
        }
    }, [searchedItem])

    return(
        <Row>
            <Col sm='12' md='2' lg='2' xl='2'>
                <Navbar />
            </Col>
            <div className="col align-self-center" style={{marginTop:'60px'}}>
                <h1 style={{textAlign:'center'}}>Equatorial General Store Incoming Inventory Records</h1>
                <Select
                value={searchedItem}
                options={options}
                onChange={handleOnSelect}
                isSearchable
                placeholder="Search an item"
                /> 
                <table className="table table-light">
                        <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Item Name</th>
                                <th scope="col">Quantity In</th>
                                <th scope="col">Units</th>
                                <th scope="col">Restock Source</th>
                                <th scope="col">Source Details</th>
                                <th scope="col">Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {areInventoryRecordsLoading ? (
                                <tr>
                                    <td colSpan="7" style={{ textAlign: 'center' }}>Loading.....</td>
                                </tr>
                            ) :
                            filteredRecords.length > 0 && inventoryRecords.length > 0 ? (
                                filteredRecords.slice(incomingStartIndex, incomingEndIndex).map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.date}</td>
                                        <td>{item.productName}</td>
                                        <td>{item.quantityin}</td>
                                        <td>{item.munits}</td>
                                        <td>{item.restocksource}</td>
                                        <td>{item.externalsourcedetails}</td>
                                        <td>{item.notes}</td>
                                    </tr>
                                ))
                            ) : (
                                inventoryRecords.slice(incomingStartIndex, incomingEndIndex).map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.date}</td>
                                        <td>{item.productName}</td>
                                        <td>{item.quantityin}</td>
                                        <td>{item.munits}</td>
                                        <td>{item.restocksource}</td>
                                        <td>{item.externalsourcedetails}</td>
                                        <td>{item.notes}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    {totalPages > 1 && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                            <FontAwesomeIcon icon={faCircleChevronLeft} style={{color: 'blue',padding: '10px 20px',border: 'none',borderRadius: '5px',marginLeft: '10px',cursor: 'pointer', fontSize:'40px'}} disabled={recordsCurrentPage === 1} onClick={() => setRecordsCurrentPage(recordsCurrentPage - 1)}/>
                        <span style={{ margin: '0 10px', color:'blue' }}>Page {recordsCurrentPage} of {totalPages}</span>
                            <FontAwesomeIcon icon={faCircleChevronRight} style={{color: 'blue',padding: '10px 20px',border: 'none',borderRadius: '5px',marginLeft: '10px',cursor: 'pointer', fontSize:'40px'}} disabled={recordsCurrentPage === totalPages} onClick={() => setRecordsCurrentPage(recordsCurrentPage + 1)}/>
                        </div>
                    )}
            </div>
            <Col sm='12' md='2' lg='2' xl='2'></Col>
        </Row>
    )
}

export default EquatorialGeneralStoreRestockingRecords